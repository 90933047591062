// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/gitlab-runner/builds/y3bh9Xns/0/axinan/fe/b2c-id-web/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/page-loading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index__index' */'@/pages/index/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:locale/saved-application",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__saved-application__index' */'@/pages/saved-application/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/application-expired",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application-status__expired__index' */'@/pages/application-status/expired/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/application-paid",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application-status__paid__index' */'@/pages/application-status/paid/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/application-not-exist",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application-status__not-exist__index' */'@/pages/application-status/not-exist/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/application",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__index' */'@/pages/application/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/maintenance",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__maintenance__index' */'@/pages/maintenance/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/upload-document-post-payment/:applicationId",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__upload-document-post-payment__applicationId__index' */'@/pages/upload-document-post-payment/[applicationId]/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/protected-screen",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__protected-screen__index' */'@/pages/protected-screen/index.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
        "layout": false
      },
      {
        "path": "/:locale/payment-status-bnul/success",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__payment-status-bnul__success__index' */'@/pages/payment-status-bnul/success/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/payment-status-bnul/failed",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__payment-status-bnul__failed__index' */'@/pages/payment-status-bnul/failed/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/payment-status-bnul/pending",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__payment-status-bnul__pending__index' */'@/pages/payment-status-bnul/pending/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/payment-options",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__payment-options__index' */'@/pages/payment-options/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "path": "/:locale/about-igloo",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__index' */'@/pages/about/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:locale/tentang-igloo",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__index' */'@/pages/about/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:locale/terms/privacypolicy",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__terms__privacy-policy__index' */'@/pages/terms/privacy-policy/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:locale/terms/termsofservice",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__terms__terms-of-service__index' */'@/pages/terms/terms-of-service/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:locale/terms/lippo-termsofservice",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__terms__lippo-termsofservice__index' */'@/pages/terms/lippo-termsofservice/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:locale/terms/purchase-terms",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__terms__purchase-terms__index' */'@/pages/terms/purchase-terms/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:locale/userAccount",
        "exact": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__userAccount' */'@/pages/userAccount'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
        "layout": false
      },
      {
        "path": "/:locale/myProfile",
        "exact": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__myProfile' */'@/pages/myProfile'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
        "layout": false
      },
      {
        "path": "/:locale/policy/:policyId",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__policy__policyId__index' */'@/pages/policy/[policyId]/index.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
        "layout": false
      },
      {
        "path": "/:locale/quote/:quoteId",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__quote__quoteId__index' */'@/pages/quote/[quoteId]/index.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})],
        "layout": false
      },
      {
        "path": "/:locale/:insuranceKey",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__insurance__insuranceKey__index' */'@/pages/insurance/[insuranceKey]/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:locale/:insuranceKey/:productKey/:searchId",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product__productKey__index' */'@/pages/product/[productKey]/index.tsx'), loading: LoadingComponent}),
        "layout": false
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
